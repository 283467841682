import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PortfolioGridItem from './PortfolioGridItem';

const Portfolio3Grid = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCaseStudy {
        nodes {
          slug
          title
          description
          feature { fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }}
        }
      }
    }
  `);
  const portfolio = data.allContentfulCaseStudy.nodes;

  return(
    <section className="portfolio_area sec_pad bg_color">
      <div className="container">
        <div className="row portfolio_gallery mb_30" id="work-portfolio">
          {portfolio.map((item, index) => (
            <PortfolioGridItem
              key={index}
              columnClass="col-lg-4"
              image={item.feature.fluid}
              title={item.title}
              description={item.description}
              slug={item.slug}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
export default Portfolio3Grid;
