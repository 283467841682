import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import './PortfolioGridItem.css';

const PortfolioGridItem = (props) => {
  const {
    image,
    title,
    description,
    slug,
    columnClass,
  } = props;

  return (
    <div className={`portfolio-grid-item mb-30 ${columnClass}`}>
      <Link to={`/portfolio/${slug}/`} className="portfolio-grid-item__wrapper">
        <Img fluid={image} className="portfolio-grid-item__image" />
        <div className="portfolio-grid-item__summary">
          <h3 className="f_p f_size_16 f_600 t_color3">{title}</h3>
        </div>
        <div className="portfolio-grid-item__hover-content">
          <div className="n_hover_content">
            <h3 className="f_p f_size_16 f_600 w_color">{title}</h3>
            <h5>{description}</h5>
            <span className="portfolio-grid-item__cta">
              Learn more 
              <i className="ti-arrow-right"></i>
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PortfolioGridItem;
